import { inject, Injectable, PLATFORM_ID, TemplateRef } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { NotifierService } from './notifier.service';
import { INotificationState, NotificationType } from '../models/notifications.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly notifierService: NotifierService = inject(NotifierService);

  private $messages: Subject<{ title: string; message: string }> = new Subject();

  public get messages() {
    return this.$messages;
  }

  public notification(notification: INotificationState | null): void {
    if (notification && notification.alertType && notification.message) {
      switch (notification.alertType) {
        case NotificationType.SUCCESS:
          this.$messages.next({ title: 'Success', message: <string>notification.message });
          break;
        case NotificationType.WARNING:
          this.$messages.next({ title: 'Warning', message: <string>notification.message });
          break;
        case NotificationType.ERROR:
          this.$messages.next({ title: 'Error', message: <string>notification.message });
          break;

        default:
          this.$messages.next({ title: 'Info', message: <string>notification.message });
      }
    }
  }

  public notifier(notificationTmpl: TemplateRef<any>) {
    return this.$messages.pipe(
      filter(() => isPlatformBrowser(this.platformId)),
      tap((notification: { title: string; message: string }) => {
        if (notification != null) {
          this.notifierService.show({
            message: notification.message,
            type: notification.title.toLowerCase(),
            template: notificationTmpl,
          });
        }
      })
    );
  }

  public hide(notificationId: string): void {
    this.notifierService.hide(notificationId);
  }
}
