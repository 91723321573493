import { inject, Injectable, PendingTasks } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

export interface SeoSocialShareData {
  title?: string;
  keywords?: string;
  description?: string;
  image?: string;
  imageAuxData?: ImageAuxData;
  url?: UrlData;
  type?: string;
  author?: string;
  section?: string;
  published?: string;
  modified?: string;
  twitterCard?: string;
}

export interface ImageAuxData {
  width?: number;
  height?: number;
  secureUrl?: string;
  mimeType?: string;
  alt?: string;
}

export interface UrlData {
  base: string;
  url: string;
}

export enum NgxSeoMetaTagAttr {
  name = 'name',
  property = 'property',
}

export interface NgxSeoMetaTag {
  attr: NgxSeoMetaTagAttr;
  attrValue: string;
  value?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoTagService {
  private readonly meta: Meta = inject(Meta);
  private readonly title: Title = inject(Title);
  private readonly document: Document = inject(DOCUMENT);
  private readonly pendingTasks: PendingTasks = inject(PendingTasks);

  public setData(data: SeoSocialShareData): void {
    this.setSection(data.section);
    this.setKeywords(data.keywords);
    this.setTitle(data.title);
    this.setType(data.type);
    this.setDescription(data.description);
    this.setImage(data.image, data.imageAuxData);
    this.setUrl(data.url);
    this.setPublished(data.published);
    this.setModified(data.modified);
    this.setAuthor(data.author);
    this.setTwitterCard(data.twitterCard);
  }

  public setKeywords(keywords?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (keywords) {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    } else {
      this.meta.removeTag(`name='keywords'`);
    }

    taskCleanup();
  }

  public setSection(section?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (section) {
      this.meta.updateTag({ name: 'article:section', content: section });
    } else {
      this.meta.removeTag(`name='article:section'`);
    }

    taskCleanup();
  }

  public setTitle(title: string = ''): void {
    const taskCleanup = this.pendingTasks.add();

    this.title.setTitle(title);

    if (title && title.length) {
      this.meta.updateTag({ name: 'twitter:title', content: title });
      this.meta.updateTag({ name: 'twitter:image:alt', content: title });
      this.meta.updateTag({ property: 'og:image:alt', content: title });
      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ name: 'title', content: title });
      this.meta.updateTag({ itemprop: 'name', content: title }, `itemprop='name'`);
    } else {
      this.meta.removeTag(`name='twitter:title'`);
      this.meta.removeTag(`name='twitter:image:alt'`);
      this.meta.removeTag(`property='og:image:alt'`);
      this.meta.removeTag(`property='og:title'`);
      this.meta.removeTag(`name='title'`);
      this.meta.removeTag(`itemprop='name'`);
    }

    taskCleanup();
  }

  public setType(type?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (type && type.length) {
      this.meta.updateTag({ property: 'og:type', content: type });
    } else {
      this.meta.removeTag(`property='og:type'`);
    }

    taskCleanup();
  }

  public setDescription(description?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (description && description.length) {
      this.meta.updateTag({ name: 'twitter:description', content: description });
      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ itemprop: 'description', content: description }, `itemprop='description'`);
    } else {
      this.meta.removeTag(`name='twitter:description'`);
      this.meta.removeTag(`property='og:description'`);
      this.meta.removeTag(`name='description'`);
      this.meta.removeTag(`itemprop='description'`);
    }

    taskCleanup();
  }

  public setImage(image?: string, auxData?: ImageAuxData): void {
    const taskCleanup = this.pendingTasks.add();

    if (image && image.length) {
      this.meta.updateTag({ name: 'twitter:image', content: image });
      this.meta.updateTag({ itemprop: 'image', content: image }, `itemprop='image'`);
      this.meta.updateTag({ property: 'og:image', content: image });
      this.meta.updateTag({ property: 'og:image:url', content: image });

      if (auxData && auxData.height) {
        this.meta.updateTag({ property: 'og:image:height', content: auxData.height.toString() });
      } else {
        this.meta.removeTag(`property='og:image:height'`);
      }

      if (auxData && auxData.width) {
        this.meta.updateTag({ property: 'og:image:width', content: auxData.width.toString() });
      } else {
        this.meta.removeTag(`property='og:image:width'`);
      }

      if (auxData && auxData.alt) {
        this.meta.updateTag({ property: 'og:image:alt', content: auxData.alt });
        this.meta.updateTag({ property: 'twitter:image:alt', content: auxData.alt });
      } else {
        this.meta.removeTag(`property='og:image:alt'`);
        this.meta.removeTag(`property='twitter:image:alt'`);
      }

      if (auxData && auxData.mimeType) {
        this.meta.updateTag({ property: 'og:image:type', content: auxData.mimeType });
      } else {
        this.meta.removeTag(`property='og:image:type'`);
      }

      if (auxData && auxData.secureUrl) {
        this.meta.updateTag({ property: 'og:image:secure_url', content: auxData.secureUrl });
      } else {
        this.meta.removeTag(`property='og:image:secure_url'`);
      }
    } else {
      this.meta.removeTag(`name='twitter:image'`);
      this.meta.removeTag(`property='twitter:image:alt'`);
      this.meta.removeTag(`property='og:image'`);
      this.meta.removeTag(`property='og:image:url'`);
      this.meta.removeTag(`property='og:image:height'`);
      this.meta.removeTag(`property='og:image:secure_url'`);
      this.meta.removeTag(`property='og:image:type'`);
      this.meta.removeTag(`property='og:image:alt'`);
      this.meta.removeTag(`property='og:image:width'`);
      this.meta.removeTag(`itemprop='image'`);
    }

    taskCleanup();
  }

  public setUrl(url?: UrlData): void {
    const taskCleanup = this.pendingTasks.add();

    if (url && url.url.length) {
      this.meta.updateTag({ property: 'og:url', content: url.base + url.url });
    } else {
      this.meta.removeTag(`property='og:url'`);
    }

    this.setCanonicalUrl(url);

    if (url) {
      if (url.url !== '/') {
        url.url += '/';
      }

      if (url.url.includes('/en/')) {
        this.setLanguageAlternativeUrl('nl', url.base + url.url.replace('/en/', '/nl/'));
        this.setLanguageOriginalUrl('en', url.base + url.url);
      } else if (url.url.includes('/nl/')) {
        this.setLanguageAlternativeUrl('en', url.base + url.url.replace('/nl/', '/en/'));
        this.setLanguageOriginalUrl('nl', url.base + url.url);
      } else if (url.url === '/') {
        this.setLanguageAlternativeUrl('nl', url.base + url.url + '/nl/');
        this.setLanguageOriginalUrl('en', url.base + url.url);
      }
    }

    taskCleanup();
  }

  public setPublished(publishedDateString?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (publishedDateString) {
      const publishedDate = new Date(publishedDateString);
      this.meta.updateTag({ name: 'article:published_time', content: publishedDate.toISOString() });
      this.meta.updateTag({ name: 'published_date', content: publishedDate.toISOString() });
    } else {
      this.meta.removeTag(`name='article:published_time'`);
      this.meta.removeTag(`name='publication_date'`);
    }

    taskCleanup();
  }

  public setModified(modifiedDateString?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (modifiedDateString) {
      const modifiedDate = new Date(modifiedDateString);
      this.meta.updateTag({ name: 'article:modified_time', content: modifiedDate.toISOString() });
      this.meta.updateTag({ name: 'og:updated_time', content: modifiedDate.toISOString() });
    } else {
      this.meta.removeTag(`name='article:modified_time'`);
      this.meta.removeTag(`name='og:updated_time'`);
    }

    taskCleanup();
  }

  public setAuthor(author?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (author && author.length) {
      this.meta.updateTag({ name: 'article:author', content: author });
      this.meta.updateTag({ name: 'author', content: author });
    } else {
      this.meta.removeTag(`name='article:author'`);
      this.meta.removeTag(`name='author'`);
    }

    taskCleanup();
  }

  public setTwitterSiteCreator(site?: string): void {
    if (site) {
      this.meta.updateTag({ name: 'twitter:site', content: site });
      this.meta.updateTag({ name: 'twitter:creator', content: site });
    } else {
      this.meta.removeTag(`name='twitter:site'`);
      this.meta.removeTag(`name='twitter:creator'`);
    }
  }

  public setTwitterCard(card?: string): void {
    const taskCleanup = this.pendingTasks.add();

    if (card) {
      this.meta.updateTag({ name: 'twitter:card', content: card });
    } else {
      this.meta.removeTag(`name='twitter:card'`);
    }

    taskCleanup();
  }

  public setMetaTag(metaTag: NgxSeoMetaTag): void {
    if (metaTag.value) {
      const metaTagObject = {
        [metaTag.attr]: metaTag.attrValue,
        content: metaTag.value,
      };
      this.meta.updateTag(metaTagObject);
    } else {
      const selector = `${metaTag.attr}='${metaTag.attrValue}'`;
      this.meta.removeTag(selector);
    }
  }

  public setMetaTags(metaTags: NgxSeoMetaTag[]): void {
    for (const metaTag of metaTags) {
      this.setMetaTag(metaTag);
    }
  }

  public setLanguageAlternativeUrl(lang: string, url?: string): void {
    if (lang && lang.length) {
      this.meta.updateTag({ property: 'og:locale:alternate', content: lang });

      if (lang == 'en') {
        this.meta.updateTag({ property: 'og:locale', content: 'nl' });
      } else if (lang == 'nl') {
        this.meta.updateTag({ property: 'og:locale', content: 'en' });
      } else {
        this.meta.removeTag(`property='og:locale:alternate'`);
      }
    } else {
      this.meta.removeTag(`property='og:locale'`);
    }

    const selector = `link[rel='alternate']`;
    const languageAlternativeElement = this.document.head.querySelectorAll(selector);

    if (languageAlternativeElement) {
      languageAlternativeElement.forEach(element => {
        this.document.head.removeChild(element);
      });
    }

    if (url && url.length) {
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }

      const altLink: HTMLLinkElement = this.document.createElement('link');
      altLink.setAttribute('rel', 'alternate');
      altLink.setAttribute('hreflang', lang);
      altLink.setAttribute('href', url);
      this.document.head.appendChild(altLink);
    }
  }

  public setLanguageOriginalUrl(lang: string, url?: string): void {
    if (url && url.length) {
      const og = url.replace('/nl/', '/en/');

      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }

      const xlink: HTMLLinkElement = this.document.createElement('link');
      xlink.setAttribute('rel', 'alternate');
      xlink.setAttribute('hreflang', 'x-default');
      xlink.setAttribute('href', og ? og : url);
      this.document.head.appendChild(xlink);

      const origLink: HTMLLinkElement = this.document.createElement('link');
      origLink.setAttribute('rel', 'alternate');
      origLink.setAttribute('hreflang', lang);
      origLink.setAttribute('href', url);
      this.document.head.appendChild(origLink);
    }
  }

  public setCanonicalUrl(url?: UrlData): void {
    const selector = `link[rel='canonical']`;

    const canonicalElement = this.document.head.querySelector(selector);
    if (canonicalElement) {
      this.document.head.removeChild(canonicalElement);
    }

    if (url && url.url.length) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url.url === '/' ? url.base : url.base + url.url);
      this.document.head.appendChild(link);
    }
  }
}
