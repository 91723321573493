export enum NotificationType {
  DEFAULT = 0,
  INFO = 1,
  SUCCESS = 2,
  WARNING = 3,
  ERROR = 4,
}

export interface INotificationState {
  alertType: NotificationType | null;
  message: string | null;
}
