export interface IEventBusMessage {
  key: string;
  metadata: EventBusMetaData;
}

export class EventBusMetaData<T = any> {
  private readonly _key: string;
  private readonly _data?: T;

  constructor(key: string, data?: T) {
    this._key = key;
    this._data = data;
  }

  public get key(): string {
    return this._key;
  }

  public get data(): T | undefined {
    return this._data;
  }
}

export interface IStoreMessage {
  status: 'success' | 'failure';
  message?: string;
}
