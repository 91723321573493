import { inject, Injectable, isDevMode, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { SERVER_URL_TOKEN } from '@owain/universal-fastify-engine/interface';
import { BASE_URL } from '../tokens/injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService {
  private readonly serverUrl: string = inject(SERVER_URL_TOKEN, { optional: true });
  private readonly baseUrl: string | null = inject(BASE_URL, { optional: true });
  private readonly platformId: Object = inject(PLATFORM_ID);

  public getBaseUrl(): string {
    let url;

    if (isPlatformBrowser(this.platformId)) {
      url = this.baseUrl;
    } else if (this.serverUrl) {
      url = this.serverUrl;
    } else {
      url = this.baseUrl;
    }

    if (!url) {
      if (!isDevMode()) {
        url = 'https://archery-events.com';
      } else {
        url = 'http://localhost:4200';
      }
    }

    // if (isPlatformServer(this.platformId)) {
    //   url = url.replace('https://archery-events.com', 'http://localhost:5555');
    // }

    if (url.includes('localhost') && url.includes('https')) {
      url = url.replace('https://', 'http://');
    }

    return url;
  }
}
