import { HttpClient } from '@angular/common/http';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BaseUrlService } from '../base-url.service';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import type { ICsrf } from '@archery-events/models/auth.model';
import type { IUser } from '@archery-events/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);
  private readonly platformId: Object = inject(PLATFORM_ID);

  csrf(): Observable<ICsrf> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/csrf`;
    return this.httpClient.get<ICsrf>(url);
  }

  status(browser: boolean): Observable<IUser | null> {
    let url = `${this.baseUrlService.getBaseUrl()}/api/auth/status`;

    if (browser) {
      url += `-browser`;
    } else {
      url += `-server`;
    }

    return this.httpClient.post<IUser | null>(url, { remove: browser });
  }

  signIn(email: string, password: string): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/sign-in`;
    return this.httpClient.post<IUser>(url, { email, password });
  }

  signInTotp(email: string, password: string, code: string): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/sign-in-totp`;
    return this.httpClient.post<IUser>(url, { email, password, code });
  }

  signOut(): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/sign-out`;
    return this.httpClient.post<void>(url, {});
  }

  refreshToken(): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/refresh-token`;
    return this.httpClient.post<IUser>(url, { remove: isPlatformBrowser(this.platformId) });
  }

  resetPassword(email: string): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/resetpassword`;
    return this.httpClient.post<void>(url, { email });
  }

  newPassword(token: string, password: string): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/newpassword`;
    return this.httpClient.post<void>(url, { token, password });
  }
}
