import { Injectable } from '@angular/core';
import { WebStorageHandler } from '@owain/localstorage/web-storage.handler';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends WebStorageHandler {
  constructor() {
    super();

    this.storage = this.window.localStorage;
  }
}
