import { inject } from '@angular/core';
import { WINDOW } from '@owain/tokens/window.provider';

export class WebStorageHandler {
  protected readonly window: Window = inject(WINDOW);
  protected storage: Storage | undefined;

  get<T>(key: string): T | null {
    const item = this.storage?.getItem(key);

    if (!item) {
      return null;
    }

    return item as T;
  }

  getJson<T>(key: string): T | null {
    const item = this.storage?.getItem(key);

    if (!item) {
      return null;
    }

    return this.isJSONValid(item) ? (JSON.parse(item) as T) : (item as T);
  }

  set(key: string, value: string): void {
    this.storage?.setItem(key, value);
  }

  setJson(key: string, value: unknown): void {
    this.storage?.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    this.storage?.removeItem(key);
  }

  removeKeys(keys: string[]): void {
    keys.forEach(key => this.storage?.removeItem(key));
  }

  clear(): void {
    this.storage?.clear();
  }

  isJSONValid(value: string): boolean {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }
}
