import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Params } from '@angular/router';
import { IBreadcrumb, JsonLd } from '@owain/seo/models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  private readonly document: Document = inject(DOCUMENT);
  private jsonLd: JsonLd[] = [];

  public setData(data: JsonLd[]): void {
    this.jsonLd = data;

    this.inject();
  }

  public clearData(language: string): void {
    this.jsonLd = [];

    this.addObject('Organization', {
      name: 'Archery Events',
      description: language == 'en' ? 'Archery Events members portal' : 'Archery Events members leden portaal',
      url: 'https://archery-events.com/',
      logo: 'https://archery-events.com/assets/img/icons/chrome/archery-events-512.png',
      email: 'admin@archery-events.com',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'admin@archery-events.com',
      },
    });
  }

  public clearDataInject(language: string): void {
    this.clearData(language);
    this.inject();
  }

  public push(object: JsonLd): void {
    this.jsonLd.push(object);
  }

  public addObject(type: string, rawData?: JsonLd, context: string = 'http://schema.org'): void {
    let object: JsonLd = {
      '@type': type,
    };
    if (context) {
      object = {
        '@context': context,
        ...object,
      };
    }
    if (rawData) {
      object = {
        ...object,
        ...rawData,
      };
    }

    this.jsonLd.push(object);
  }

  public addLocalImage(url: string) {
    this.addImage(
      {
        contentUrl: `https://archery-events.com/assets/svg/${url}.svg`,
      },
      {
        acquireLicensePage: 'https://archery-events.com/en/contact',
        creditText: 'Owain van Brakel - https://archery-events.com/',
        copyrightNotice: 'Owain van Brakel - https://archery-events.com/',
      },
      {
        '@type': 'Person',
        name: 'Owain van Brakel',
      }
    );
  }

  public addHeroiconImage(url: string) {
    this.addImage(
      {
        contentUrl: `https://archery-events.com/assets/svg/${url}.svg`,
      },
      {
        license: 'https://github.com/tailwindlabs/heroicons/blob/master/LICENSE',
        acquireLicensePage: 'https://github.com/tailwindlabs/heroicons',
        creditText: 'heroicons - tailwindlabs',
        copyrightNotice: 'heroicons - tailwindlabs',
      },
      {
        '@type': 'Organization',
        name: 'tailwindlabs',
        url: 'https://github.com/tailwindlabs/',
        sameAs: 'https://tailwindcss.com/',
        image: 'https://upload.wikimedia.org/wikipedia/commons/9/95/Tailwind_CSS_logo.svg',
      }
    );
  }

  public addImage(
    rawData: JsonLd,
    extra?: JsonLd,
    creator: JsonLd = {
      '@type': 'Person',
      name: 'Owain van Brakel',
    },
    context: string = 'http://schema.org'
  ): void {
    let object: JsonLd = {
      '@type': 'ImageObject',
      ...rawData,
    };

    if (context) {
      object = {
        '@context': context,
        ...object,
      };
    }

    if (creator) {
      object = {
        creator: creator,
        ...object,
      };
    }

    if (extra) {
      object = {
        ...extra,
        ...object,
      };
    }

    this.jsonLd.push(object);
  }

  public addEvent(rawData: JsonLd, organizer?: JsonLd, images?: string[], context: string = 'http://schema.org'): void {
    let object: JsonLd = {
      '@type': 'Event',
      ...rawData,
    };

    if (organizer) {
      object = {
        ...object,
        organizer: organizer,
      };
    }

    if (images) {
      object = {
        ...object,
        image: images,
      };
    }

    if (context) {
      object = {
        '@context': context,
        ...object,
      };
    }

    this.jsonLd.push(object);
  }

  public setBreadcrumbs(rawData: JsonLd[], lang: string, params: Params, context: string = 'http://schema.org'): void {
    const breadcrumbs: JsonLd[] = [];
    let modifier = 0;

    for (const [i, value] of rawData.entries()) {
      let breadcrumb: IBreadcrumb = {
        name: '',
      };

      if (value['name'] && typeof value['name'] === 'string') {
        breadcrumb.name = value['name'];

        if (value['name'].includes(':')) {
          const nameParam = params[value['name'].replace(':', '')];

          if (nameParam) {
            breadcrumb.name = this.titleCase(nameParam);
          }
        }
      }

      if (value['item'] && typeof value['item'] === 'string') {
        breadcrumb = {
          ...breadcrumb,
          item: {
            name: breadcrumb.name,
            '@id': '',
          },
        };

        if (value['item'].startsWith('/en') || value['item'].startsWith('/nl')) {
          breadcrumb.item!['@id'] = 'https://archery-events.com' + value['item'];
        } else {
          breadcrumb.item!['@id'] = 'https://archery-events.com' + '/' + lang + '/' + value['item'];
        }

        if (breadcrumb.item!['@id'].endsWith('/')) {
          breadcrumb.item!['@id'] = breadcrumb.item!['@id'].slice(0, -1);
        }

        if (value['item'].includes(':')) {
          const split = value['item'].split('/');
          let newItem = '';

          for (const i of split) {
            if (i.includes(':')) {
              const itemParam = params[i.replace(':', '')];

              if (itemParam) {
                newItem += itemParam + '/';
              } else {
                newItem += i + '/';
              }
            } else {
              newItem += i + '/';
            }
          }

          breadcrumb.item!['@id'] = newItem;

          if (breadcrumb.item!['@id'].endsWith('/')) {
            breadcrumb.item!['@id'] = breadcrumb.item!['@id'].slice(0, -1);
          }
        }
      }

      breadcrumbs.push({
        '@type': 'ListItem',
        position: i + 1 - modifier,
        ...breadcrumb,
      });
    }

    let object: JsonLd = {
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs,
    };

    if (context) {
      object = {
        '@context': context,
        ...object,
      };
    }

    this.jsonLd.push(object);
  }

  public inject(): void {
    let ldJsonScriptTag = this.document.head.querySelector(`script[type='application/ld+json']`);

    if (this.jsonLd === null) {
      if (ldJsonScriptTag) {
        this.document.head.removeChild(ldJsonScriptTag);
      }
    } else if (ldJsonScriptTag) {
      ldJsonScriptTag.textContent = JSON.stringify(this.jsonLd);
    } else {
      ldJsonScriptTag = this.document.createElement('script');
      ldJsonScriptTag.setAttribute('type', 'application/ld+json');
      ldJsonScriptTag.textContent = JSON.stringify(this.jsonLd);
      this.document.head.appendChild(ldJsonScriptTag);
    }
  }

  titleCase(input: string): string {
    return input
      .toLowerCase()
      .replaceAll('-', ' ')
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
