import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EventBusMetaData, IEventBusMessage } from './event-bus.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private eventBus: Subject<IEventBusMessage>;

  private separator: string = ':';

  constructor() {
    this.eventBus = new Subject<IEventBusMessage>();
  }

  public keyMatch(key: string, wildcard: string): boolean {
    const singleWildcard: string = '*';
    const doubleWildcard: string = '**';

    const partMatch = (wildcard: string, key: string): boolean => wildcard === singleWildcard || wildcard === key;

    const separator: string = this.separator;
    const keys: string[] = key.split(separator);
    const wildcards: string[] = wildcard.split(separator);

    const keyLength: number = keys.length;
    const wildcardLength: number = wildcards.length;
    const max: number = Math.max(keyLength, wildcardLength);

    for (let i: number = 0; i < max; i++) {
      const key: string = keys[i];
      const wildcard: string = wildcards[i];

      if (wildcard === doubleWildcard && typeof key !== 'undefined') {
        return true;
      }

      if (!partMatch(wildcard, key)) {
        return false;
      }
    }

    return true;
  }

  public cast<T>(key: string, data?: T): void {
    if (!key.trim().length) {
      throw new Error('key parameter must be a string and must not be empty');
    }

    const metadata: EventBusMetaData<T> = new EventBusMetaData<T>(key, data);

    this.eventBus.next({ key, metadata });
  }

  public on<T>(key: string): Observable<EventBusMetaData<T>> {
    return this.eventBus.asObservable().pipe(
      filter((event: IEventBusMessage): boolean => this.keyMatch(event.key, key)),
      map((event: IEventBusMessage): EventBusMetaData<T> => event.metadata)
    );
  }
}
