import { PartialStateUpdater, patchState, Prettify, WritableStateSource } from '@ngrx/signals';
import { produce } from 'immer';

export type ImmerStateUpdater<State extends object> = (state: State) => void;

/**
 * Helper method that wraps a reducer with the Immer `produce` method
 * Kudos to Alex Okrushko {@link https://lookout.dev/rules/simple-immer-base-function-to-be-used-in-ngrx-store-or-componentstore-for-transforming-data-%22mutably%22}
 */
export function immerReducer<State, Next>(callback: (state: State, next: Next) => State | void) {
  return (state: State | undefined, next: Next) => {
    return produce(state, (draft: State) => callback(draft, next)) as State;
  };
}

function toFullStateUpdater<State extends object>(
  updater: PartialStateUpdater<State & {}> | ImmerStateUpdater<State & {}>
): (state: State) => State | void {
  return (state: State) => {
    const patchedState = updater(state);
    if (patchedState) {
      return { ...state, ...patchedState };
    }
    return;
  };
}

export function immerPatchState<State extends object>(
  state: WritableStateSource<State>,
  ...updaters: Array<
    Partial<Prettify<State>> | PartialStateUpdater<Prettify<State>> | ImmerStateUpdater<Prettify<State>>
  >
): void {
  const immerUpdaters = updaters.map(updater => {
    if (typeof updater === 'function') {
      return immerReducer(toFullStateUpdater(updater)) as unknown as PartialStateUpdater<State & {}>;
    }
    return updater;
  });
  patchState(state, ...immerUpdaters);
}
