import { inject, PLATFORM_ID } from '@angular/core';
import { SERVER_CONTEXT } from '@owain/universal-fastify-engine/interface';
import { isPlatformServer } from '@angular/common';

export function getServerContext(): string {
  const platformId: Object = inject(PLATFORM_ID);
  const context: string | null = inject(SERVER_CONTEXT, { optional: true });

  let serverContext: string;

  if (!isPlatformServer(platformId)) {
    serverContext = 'csr';
  } else {
    if (context) {
      serverContext = context;
    } else {
      serverContext = 'ssg';
    }
  }

  return serverContext;
}
