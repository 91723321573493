import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BaseUrlService } from '../base-url.service';
import { Observable } from 'rxjs';
import type { ITotpVerifyEnabled } from '@archery-events/models/auth.model';
import type { INameUpdate, IUser } from '@archery-events/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly baseUrlService: BaseUrlService = inject(BaseUrlService);

  totp(): Observable<ITotpVerifyEnabled> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/totp`;
    return this.httpClient.post<ITotpVerifyEnabled>(url, {});
  }

  enableTotp(secret: string, code: string): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/enable-totp`;
    return this.httpClient.post<void>(url, { secret, code });
  }

  disableTotp(code: string): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/auth/disable-totp`;
    return this.httpClient.post<void>(url, { code });
  }

  fullnameUpdate(name: INameUpdate): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/user/fullname`;
    return this.httpClient.put<IUser>(url, {
      firstname: name.firstname,
      insertion: name.insertion,
      lastname: name.lastname,
    });
  }

  emailUpdate(email: string): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/user/email`;
    return this.httpClient.put<IUser>(url, { email });
  }

  passwordUpdate(password: string): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/user/password`;
    return this.httpClient.put<IUser>(url, { password });
  }

  verifyEmail(token: string): Observable<IUser> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/user/verify-email`;
    return this.httpClient.post<IUser>(url, { token }); // Should be a put request but angular can't transfer those in the hydration state
  }

  resendVerifyEmail(): Observable<void> {
    const url = `${this.baseUrlService.getBaseUrl()}/api/user/resend-verify-email`;
    return this.httpClient.post<void>(url, {});
  }
}
